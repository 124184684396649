:root {
  --body-background: #e8eaf9;
  --body-color: #274f40;
  --heading-color: #ee645a;
  --action-color: #d64045;
}

@font-face {
  font-family: FibelNord;
  src: url(../fonts/fibelnord.ttf);
}

@font-face {
  font-family: EpilogueRegular;
  src: url(../fonts/epilogueregular.ttf);
}

@font-face {
  font-family: EpilogueBold;
  src: url(../fonts/epiloguebold.ttf);
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: EpilogueRegular, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 16px;
  font-size: 108%;
  line-height: 1.5;
  min-width: 275px;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #ffb088;
}

h1 {
  font-family: EpilogueBold, sans-serif;
  margin: 1rem 0 1rem;
  text-align: center;
  font-weight: 900;
  color: var(--heading-color);
  line-height: 1.2;
  /* all caps */
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bolder;
  letter-spacing: 0.1em;
}

h4 {
  margin: 1rem 0;
  font-family: EpilogueBold, sans-serif;
}

@media (max-width: 500px) {
  h1 {
    font-size: 1.5rem;
  }
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  height: 240px;
  max-width: 100%;
  object-fit: contain;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
}

main {
  margin: 1rem auto 1rem;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  background: white;
  box-shadow: 2px 3px 3px #ddd;
  border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

.content {
  margin: 2rem auto;
  max-width: 65rem;
  position: relative;
  border-radius: 8px;
  box-sizing: border-box;
}

.innercontent {
  position: relative;
}

.name {
  font-style: italic;
}

.white {
  background-color: white;
}

.sponsors {
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.sponsor {
  max-width: 192px;
  max-height: 192px;
}

.sponsor-image {
  object-fit: contain;
  width: 192px;
  height: 192px;
  margin: 16px;
}

.large-image {
  box-sizing: border-box;
  max-width: 100%;
  object-fit: contain;
  border-radius: 8px;
  display: block;
}

.icon {
  width: 20px;
  height: 20px;
  vertical-align: sub;
  margin-right: 0.2em;
}
.attribution {
  background-color: white;
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin: 0;
  padding: 0.2em 0.5em 0.05em 0.4em;
  border-radius: 3px;
  text-align: right;
}

@media (min-width: 960px) {
  .pip {
    position: absolute;
    border-radius: 3px;
    right: 10px;
    top: 10px;
    width: 250px;
    height: 250px;
    margin: 0;
  }
  iframe {
    border-radius: 3px;
  }
}

@media (max-width: 960px) {
  .pip {
    margin: 1rem auto;
    width: 250px;
    max-width: 100%;
    height: 250px;
  }
}
